// ITSCSS equivalent objects/texts.scss

@use './breakpoints';
@import './mixins';
@import 'responsive/utils/mediaQueries.module';

$primary-weight-light: 400;
$primary-weight-regular: 600;
$primary-weight-black: 900;
$secondary-weight-light: 300;

%text-base {
	text-rendering: optimizelegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

%text-common {
	@extend %text-base;

	font-family: var(--mango-primary-font);
}

%text-common-secondary {
	@extend %text-base;

	font-family: var(--mango-secondary-font);
	font-weight: $secondary-weight-light;
}

%text-uppercase {
	text-transform: uppercase;
}

%text-underline {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
}

%primary-regular {
	@extend %text-common;

	font-weight: $primary-weight-regular;
}

%primary-light {
	@extend %text-common;

	font-weight: $primary-weight-light;
}

%text-common-promotional {
	@extend %text-base;

	font-family: var(--mango-promotional-font);
	font-weight: $primary-weight-black;

	@extend %text-uppercase;
}

%text-regular-uppercase {
	@extend %primary-regular;
	@extend %text-uppercase;
}

// Specific styles

// TITLE
%text-title-base {
	@extend %text-common;
	@extend %text-uppercase;

	font-weight: $primary-weight-regular;
}

%text-title-xxxl {
	@extend %text-title-base;

	font-size: px2rem(40);
	line-height: px2rem(40);

	@include onLargeOrGreater {
		font-size: px2rem(68);
		line-height: px2rem(68);
	}
}

%text-title-xxl {
	@extend %text-title-base;

	font-size: px2rem(18);
	line-height: px2rem(22);

	@include onLargeOrGreater {
		font-size: px2rem(30);
		line-height: px2rem(30);
	}
}

%text-title-xl {
	@extend %text-title-base;

	font-size: px2rem(16);
	line-height: px2rem(20);
}

%text-title-l,
%text-title-m {
	@extend %text-title-base;

	font-size: px2rem(13);
	line-height: px2rem(18);
}

%text-title-s {
	@extend %text-title-base;

	font-size: px2rem(11);
	line-height: px2rem(16);
}

// BODY

%text-body-l,
%text-body-m,
%text-body-m-regular-underline,
%text-body-m-regular {
	font-size: px2rem(13);
	line-height: px2rem(18);
}

%text-body-l,
%text-body-m {
	@extend %primary-light;
}

%text-body-m-regular-underline,
%text-body-m-regular {
	@extend %primary-regular;
}

%text-body-m-regular-underline {
	@extend %text-underline;
}

%text-body-s-regular-underline,
%text-body-s-regular,
%text-body-s-underline,
%text-body-s {
	font-size: px2rem(10);
	line-height: px2rem(12);
}

%text-body-s-underline,
%text-body-s {
	@extend %primary-light;
}

%text-body-s-regular-underline,
%text-body-s-regular {
	@extend %primary-regular;
}

%text-body-s-regular-underline,
%text-body-s-underline {
	@extend %text-underline;
}

// UPPERCASE

%text-uppercase-xxl {
	@extend %text-regular-uppercase;

	font-size: px2rem(40);
	line-height: px2rem(40);

	@include onLargeOrGreater {
		font-size: px2rem(68);
		line-height: px2rem(68);
	}
}

%text-uppercase-xl,
%text-uppercase-l,
%text-uppercase-m {
	@extend %text-regular-uppercase;

	font-size: px2rem(13);
	line-height: px2rem(18);
}

%text-uppercase-s {
	@extend %text-regular-uppercase;

	font-size: px2rem(11);
	line-height: px2rem(16);
}

%text-uppercase-xs {
	@extend %primary-light;
	@extend %text-uppercase;

	font-weight: $primary-weight-light;
	font-size: px2rem(9);
	line-height: px2rem(12);
}

%text-inspirational-title-xxxl {
	@extend %text-common-secondary;

	font-size: px2rem(112);
	line-height: px2rem(128);
}

%text-inspirational-title-xxl {
	@extend %text-common-secondary;

	font-size: px2rem(64);
	line-height: px2rem(60);

	@include onLargeOrGreater {
		font-size: px2rem(80);
		line-height: px2rem(88);
	}
}

%text-inspirational-title-xl {
	@extend %text-common-secondary;

	font-size: px2rem(47);
	line-height: px2rem(48);

	@include onLargeOrGreater {
		font-size: px2rem(72);
		line-height: px2rem(76);
	}
}

%text-inspirational-title-l {
	@extend %text-common-secondary;

	font-size: px2rem(40);
	line-height: px2rem(44);

	@include onLargeOrGreater {
		font-size: px2rem(56);
		line-height: px2rem(60);
	}
}

%text-inspirational-title-m {
	@extend %text-common-secondary;

	font-size: px2rem(32);
	line-height: px2rem(36);

	@include onLargeOrGreater {
		font-size: px2rem(48);
		line-height: px2rem(56);
	}
}

%text-inspirational-title-s {
	@extend %text-common-secondary;

	font-size: px2rem(24);
	line-height: px2rem(28);

	@include onLargeOrGreater {
		font-size: px2rem(29);
		line-height: px2rem(32);
	}
}

%text-inspirational-body-l {
	@extend %text-common-secondary;

	font-size: px2rem(30);
	line-height: px2rem(36);

	@include onLargeOrGreater {
		font-size: px2rem(40);
		line-height: px2rem(44);
	}
}

%text-inspirational-body-s {
	@extend %text-common-secondary;

	font-size: px2rem(22);
	line-height: px2rem(30);

	@include onLargeOrGreater {
		font-size: px2rem(26);
		line-height: px2rem(34);
	}
}

%text-promo-title-xxl {
	@extend %text-common-promotional;

	font-size: px2rem(58);
	line-height: px2rem(54);

	@include onLargeOrGreater {
		font-size: px2rem(88);
		line-height: px2rem(96);
	}
}

%text-promo-title-xl {
	@extend %text-common-promotional;

	font-size: px2rem(40);
	line-height: px2rem(48);

	@include onLargeOrGreater {
		font-size: px2rem(56);
		line-height: px2rem(60);
	}
}

%text-promo-title-l {
	@extend %text-common-promotional;

	font-size: px2rem(32);
	line-height: px2rem(36);

	@include onLargeOrGreater {
		font-size: px2rem(48);
		line-height: px2rem(56);
	}
}

%text-promo-title-m {
	@extend %text-common-promotional;

	font-size: px2rem(24);
	line-height: px2rem(32);
}

%text-promo-title-s {
	@extend %text-common-promotional;

	font-size: px2rem(18);
	line-height: px2rem(24);
}

%text-promo-title-xs {
	@extend %text-common-promotional;

	font-size: px2rem(12);
	line-height: px2rem(18);
}

%text-input {
	@extend %text-common;

	font-weight: 300;
	font-size: px2rem(16);
	line-height: px2rem(22);

	@include onLargeOrGreater {
		font-size: px2rem(13);
		line-height: px2rem(20);
	}
}
