$image-width: 100px;
$image-height: 140px;

@use 'motion';
@use 'texts';
@import 'responsive/utils/mediaQueries.module';

.container {
	animation: loading-snackbar-animation motion.$duration-short3 motion.$easing-calm motion.$delay-short3;
	animation-fill-mode: both;
	will-change: opacity;
}

.body {
	display: flex;
	flex-direction: row;
	width: 300px;
	height: $image-height;

	@include onSmall {
		min-width: 100%;
	}
}

.details {
	padding-block-end: 16px;
	padding-inline: 16px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-width: 0;
	width: 100%;
}

.productName {
	margin-block: 0;
	display: block;
	margin: 0;
	overflow: hidden;
	padding-bottom: 2px;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.price {
	margin-block: 0;
	display: flex;
}

.size {
	margin-block: 12px 0;
}

.imageWrapper {
	flex: 0 0 $image-width;
	width: $image-width;
	height: $image-height;
	position: relative;
	overflow: hidden;
}

.image {
	opacity: 0;
}

.image--animated {
	animation: loading-snackbar-animation motion.$duration-short3 motion.$easing-calm motion.$delay-short3;
	animation-fill-mode: both;
	will-change: opacity, transform;
}

.buttonWrapper {
	padding-block-start: 16px;
}
.button {
	@extend %text-body-m-regular;

}


@keyframes loading-snackbar-animation {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
